<template>
  <a-card v-if="datasets.length > 0" :bordered="true" class="household-summary-table mb-32">
    <div class="txt-20 txt-font-din-medium txt-black txt-bold mb-20">{{ $t('components.titles.householdSummary') }}</div>
    <a-table
      :scroll="{ y: 300 }"
      :columns="columns"
      :data-source="datasets"
      table-layout="fixed"
      size="small"
      :pagination="false">
      <template slot="footer">
        <table class="footer-table">
          <tr class="txt-black txt-font-din-medium">
            <td colspan="3">{{ $t('components.labels.total').toUpperCase() }}</td>
            <td>{{ sumBy(datasets, 'members') }}</td>
            <td>{{ sumBy(datasets, 'male') }}</td>
            <td>{{ sumBy(datasets, 'female') }}</td>
            <td>{{ sumBy(datasets, 'joined') }}</td>
            <td>{{ sumBy(datasets, 'left') }}</td>
            <td>{{ sumBy(datasets, 'born') }}</td>
            <td>{{ sumBy(datasets, 'died') }}</td>
          </tr>
        </table>
      </template>
    </a-table>
  </a-card>
</template>

<script>
import sumBy from 'lodash/sumBy';


function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

const exampleData = [
  {
    key: '1',
    cluster: 12,
    team: 1,
    hh: getRandomInt(50),
    members: getRandomInt(20),
    male: getRandomInt(55),
    female: getRandomInt(55),
    joined: getRandomInt(25),
    left: getRandomInt(25),
    born: getRandomInt(20),
    died: getRandomInt(20)
  }
];

export default {
  name: 'MortalityAgeGroupTable',
  props: {
    rowSummaryObject: {
      type: Object,
      required: true
    }
  },
  computed: {
    columns() {
      const columns = [
        {
          title: this.$tc('components.titles.cluster', 1),
          dataIndex: 'cluster',
          key: 'cluster'
        },
        {
          title: this.$tc('components.titles.team', 1),
          dataIndex: 'team',
          key: 'team'
        },
        {
          title: this.$t('components.titles.hh'),
          dataIndex: 'hh',
          key: 'hh'
        },
        {
          title: this.$t('components.titles.hhMembers'),
          dataIndex: 'members',
          key: 'members'
        },
        {
          title: this.$tc('values.male', 1),
          dataIndex: 'male',
          key: 'male'
        },
        {
          title: this.$tc('values.female', 1),
          dataIndex: 'female',
          key: 'female'
        },
        {
          title: this.$t('components.titles.joined'),
          dataIndex: 'joined',
          key: 'joined'
        },
        {
          title: this.$t('components.titles.left'),
          dataIndex: 'left',
          key: 'left'
        },
        {
          title: this.$t('components.titles.born'),
          dataIndex: 'born',
          key: 'born'
        },
        {
          title: this.$t('components.titles.died'),
          dataIndex: 'died',
          key: 'died'
        }
      ];
      return columns;
    },
    datasets() {
      const transformData = [];
      let index = 0;
      for (const [hhKey, hhValue] of Object.entries(this.rowSummaryObject)) {
        for (const [clusterKey, value] of Object.entries(hhValue)) {
          transformData.push({
            key: index,
            index,
            hh: hhKey,
            cluster: clusterKey,
            ...value
          });
          index += 1;
        }
      }
      return transformData;
    }
  },
  methods: {
    sumBy
  }
};
</script>

<style lang="scss">
.household-summary-table {
  .ant-table-thead {
    th:nth-child(1),
    th:nth-child(2),
    th:nth-child(3) {
      opacity: 0.42;
    }
  }
  .ant-table-tbody {
    tr {
      td:nth-child(1),
      td:nth-child(2),
      td:nth-child(3) {
        opacity: 0.42;
      }
    }
  }
  .footer-table {
    td {
      padding: 8px;
    }
  }
  .ant-table-small {
    border: none;
  }
  .ant-table-small > .ant-table-content > .ant-table-footer {
    background: #fafafa;
  }
  .ant-table-small > .ant-table-title, .ant-table-small > .ant-table-content > .ant-table-footer {
    padding: 0;
  }
  .ant-table-body table {
    border-top: 1px solid #e8e8e8 !important;
  }
}
.ant-table-footer {
  padding: 0;
}
</style>
